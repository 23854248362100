import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerResourcePage from "../../components/molecules/InnerResourcePage"
import { resources } from "../../content/Resources"

const slug = `support-team-profile`;

const post = resources.find(post => post.slug === slug) // Find the blog post by slug

const HousingProvidersProfile = () => (
  <Layout>
    <Seo title="Housing Providers Profile" />

    <InnerResourcePage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default HousingProvidersProfile
